import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({schemaData, description, lang, meta, title, cover }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  let article = schemaData ? schemaData.type : null;
  const schemaOrgJSONLD = [];
  const metaDescription = description || site.siteMetadata.description
  if (true) {
    schemaOrgJSONLD.push({
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: 'http://www.nunuma.com',
      name: 'Nunuma',
    })
  }
  if (article === 'article') {
    schemaOrgJSONLD.push(
      {
        "@context": "https://schema.org",
        "@type": "article",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://www.nunuma.com/"
        },
        "headline": schemaData.title,
        "description": schemaData.intro,
        "image": {
          "@type": "ImageObject",
          "url": schemaData.cover.localFile.url,
          "width": 1280,
          "height": 720
        },
        "author": {
          "@type": "Organization",
          "name": "Nunuma"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Nunuma",
          "logo": {
            "@type": "ImageObject",
            "url": cover ? cover : "images.ctfassets.net/19yvuqa76ak4/4zJNb5KT8n9CZi0rTqsWCX/17cb216e79df28a5ec49ab5eb0ea96b3/logo.png",
            "width": 200,
            "height": 200
          }
        },
        "datePublished": schemaData.createdAt,
        "dateModified": schemaData.updatedAt
      }
    )
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `og:image`,
          content: cover ? cover : "images.ctfassets.net/19yvuqa76ak4/4zJNb5KT8n9CZi0rTqsWCX/17cb216e79df28a5ec49ab5eb0ea96b3/logo.png",
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:site`,
          content: `@NunumaHQ`,
        },
        {
          name: `twitter:image`,
          content: cover ? cover : "images.ctfassets.net/19yvuqa76ak4/4zJNb5KT8n9CZi0rTqsWCX/17cb216e79df28a5ec49ab5eb0ea96b3/logo.png",
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>

  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
