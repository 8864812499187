/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ slimHeader, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        slimHeader={slimHeader}
        siteTitle={data.site.siteMetadata.title}
      />
      <div className={""}>
        <main className={`mb-12 overflow-hidden`}>{children}</main>
        {/* <div style={{color: '#D2CFDD'}} className={`uppercase font-bold max-w-2xl mx-auto mb-12`}>
          <div className={`flex flex-col lg:flex-row items-center lg:justify-around mb-2 mx-auto`}>
            <p className={`text-center`}>Marketplaces</p>
            <p className={`text-center`}>e-commerce</p>
            <p className={`text-center`}>b2b</p>
            <p className={`text-center`}>customer and vendor portals</p>
          </div>
          <div className={`flex flex-col lg:flex-row items-center lg:justify-around mb-2 mx-auto`}>
            <p className={`text-center`}>internet of things</p>
            <p className={`text-center`}>business analytics</p>
            <p className={`text-center`}>supply chain management</p>
          </div>
          <div className={`flex flex-col lg:flex-row items-center lg:justify-around mb-2 mx-auto`}>
            <p className={`text-center`}>HIPAA Compliant applications</p>
          </div>
        </div> */}
        <div className={"flex items-center justify-around mb-6"}>
          <svg
            width="85"
            height="63"
            viewBox="0 0 85 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.28193 49.5073L3.38568 50.9616C4.27713 49.8369 5.47213 49.2746 6.97069 49.2746C8.2925 49.2746 9.27617 49.6663 9.9217 50.4497C10.5672 51.2331 10.8977 52.4043 10.9131 53.9634V62.096H7.58164V54.0448C7.58164 53.3312 7.42794 52.8154 7.12055 52.4974C6.81315 52.1716 6.3021 52.0087 5.58741 52.0087C4.64985 52.0087 3.94668 52.4121 3.4779 53.2187V62.096H0.146484V49.5073H3.28193Z"
              fill="#3F2C7E"
            />
            <path
              d="M20.9188 60.8161C20.0965 61.8245 18.9592 62.3287 17.5067 62.3287C16.1695 62.3287 15.1474 61.9408 14.4404 61.1652C13.7411 60.3895 13.3838 59.2532 13.3684 57.7562V49.5073H16.6998V57.6399C16.6998 58.9507 17.2915 59.6061 18.475 59.6061C19.6047 59.6061 20.3809 59.2106 20.8035 58.4194V49.5073H24.1465V62.096H21.011L20.9188 60.8161Z"
              fill="#3F2C7E"
            />
            <path
              d="M29.7257 49.5073L29.8295 50.9616C30.7209 49.8369 31.9159 49.2746 33.4145 49.2746C34.7363 49.2746 35.72 49.6663 36.3655 50.4497C37.011 51.2331 37.3415 52.4043 37.3569 53.9634V62.096H34.0254V54.0448C34.0254 53.3312 33.8717 52.8154 33.5644 52.4974C33.257 52.1716 32.7459 52.0087 32.0312 52.0087C31.0937 52.0087 30.3905 52.4121 29.9217 53.2187V62.096H26.5903V49.5073H29.7257Z"
              fill="#3F2C7E"
            />
            <path
              d="M47.3626 60.8161C46.5403 61.8245 45.403 62.3287 43.9505 62.3287C42.6133 62.3287 41.5912 61.9408 40.8842 61.1652C40.1849 60.3895 39.8276 59.2532 39.8122 57.7562V49.5073H43.1436V57.6399C43.1436 58.9507 43.7353 59.6061 44.9188 59.6061C46.0485 59.6061 46.8247 59.2106 47.2473 58.4194V49.5073H50.5903V62.096H47.4548L47.3626 60.8161Z"
              fill="#3F2C7E"
            />
            <path
              d="M56.2272 49.5073L56.3309 50.9151C57.2147 49.8214 58.4097 49.2746 59.9159 49.2746C61.5221 49.2746 62.6249 49.9145 63.2243 51.1943C64.1004 49.9145 65.3492 49.2746 66.9707 49.2746C68.3232 49.2746 69.33 49.6741 69.9909 50.473C70.6518 51.2641 70.9822 52.4586 70.9822 54.0564V62.096H67.6393V54.0681C67.6393 53.3545 67.501 52.8348 67.2243 52.509C66.9476 52.1755 66.4596 52.0087 65.7603 52.0087C64.7613 52.0087 64.0696 52.4896 63.6854 53.4514L63.6969 62.096H60.3655V54.0797C60.3655 53.3506 60.2233 52.8232 59.939 52.4974C59.6547 52.1716 59.1705 52.0087 58.4865 52.0087C57.5413 52.0087 56.8573 52.4043 56.4347 53.1955V62.096H53.1033V49.5073H56.2272Z"
              fill="#3F2C7E"
            />
            <path
              d="M80.7805 62.096C80.6268 61.7935 80.5154 61.4173 80.4462 60.9674C79.6393 61.8749 78.5903 62.3287 77.2992 62.3287C76.0773 62.3287 75.0629 61.9719 74.256 61.2583C73.4568 60.5447 73.0571 59.6449 73.0571 58.559C73.0571 57.2249 73.5451 56.2011 74.5211 55.4875C75.5048 54.7739 76.9227 54.4132 78.7747 54.4055H80.3079V53.6841C80.3079 53.1024 80.158 52.637 79.8583 52.288C79.5663 51.9389 79.1013 51.7644 78.4635 51.7644C77.9025 51.7644 77.4606 51.9001 77.1378 52.1716C76.8228 52.4431 76.6652 52.8154 76.6652 53.2885H73.3338C73.3338 52.5594 73.5567 51.8846 74.0024 51.2641C74.4481 50.6436 75.0783 50.1588 75.8929 49.8098C76.7075 49.453 77.622 49.2746 78.6364 49.2746C80.1734 49.2746 81.3914 49.6663 82.2906 50.4497C83.1974 51.2253 83.6508 52.319 83.6508 53.7307V59.1873C83.6585 60.3818 83.8237 61.2854 84.1465 61.8982V62.096H80.7805ZM78.0254 59.7574C78.5173 59.7574 78.9707 59.6488 79.3857 59.4316C79.8007 59.2067 80.1081 58.9081 80.3079 58.5358V56.3717H79.0629C77.3953 56.3717 76.5077 56.9535 76.4001 58.1169L76.3886 58.3147C76.3886 58.7336 76.5346 59.0787 76.8266 59.3502C77.1186 59.6217 77.5182 59.7574 78.0254 59.7574Z"
              fill="#3F2C7E"
            />
            <path
              d="M55.8516 7.82709L27.5958 0V1.64887L49.6867 9.91431L55.8516 7.82709Z"
              fill="#3F2C7E"
            />
            <path
              d="M27.5957 18.5527L56.5124 9.25336V25.7312L27.5957 35.0306V18.5527Z"
              fill="#3F2C7E"
            />
          </svg>
        </div>

        <footer
          className={`text-center px-4 py-8 bg-purple-700`}
          style={{ color: "#D2CFDD" }}
        >
          <a
            href="https://offset.earth/nunumainc"
            target="_blank"
            rel="noopener noreferrer"
            title="View our Offset Earth profile"
            style={{ width: "120px" }}
            className={`inline-block mb-4`}
          >
            <img
              alt="We offset our carbon footprint via Offset Earth"
              src="https://offset-earth-user-assets.imgix.net/badges/climate-positive-workforce-white-landscape.png"
              style={{ width: "120px" }}
            />
          </a>
          <div
            className={`mx-auto max-w-lg flex flex-col md:flex-row items-center justify-around text-purple-200 text-sm mb-4`}
          >
            <Link to={`/privacy`}>
              <p>Privacy Policy</p>
            </Link>
            <Link to={`/terms`}>
              <p>Terms of Service</p>
            </Link>
            <Link to={`/cookies`}>
              <p>Cookie Policy</p>
            </Link>
            <Link to={`/web-development-careers`}>
              <p>Careers</p>
            </Link>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
